const TableAccelerators = ({ acceleratorsList }) => {
  const { acceleratorsPrimary, acceleratorsSecondary } = acceleratorsList;
  return (
    <>
      <table className="w-full">
        <thead>
          <tr>
            <th className="lg:w-[178px] w-[125px]">EAN</th>
            <th>DESCRIÇÃO</th>
            {/** <th>LINHA</th> */}
          </tr>
        </thead>
        <tbody>
          {acceleratorsPrimary.map((item) => (
            <tr key={item.id}>
              <td className="flex">{item.product_ean}</td>
              <td>{item.product_sku}</td>
              {/** <td>SMART</td> */}
            </tr>
          ))}
          {acceleratorsSecondary.map((item) => (
            <tr key={item.id}>
              <td className="flex">{item.product_ean}</td>
              <td>{item.product_sku}</td>
              {/** <td className="!text-[#FFD800]">SMART</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TableAccelerators;
